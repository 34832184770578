<template>
  <item-broadcast :message="arMessageList" />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { usePusher } from "@/services/pusher";
import { Channel } from "pusher-js";
import { AuthModule } from "@/store/auth";
import { InvoiceSignedChannel } from "@/types/broadcast";
import { delay } from "lodash";
import ItemBroadcast from "@/components/common/broadcast/ItemBroadcast.vue";
import { AppModule } from "@/store/app";

@Component({
  components: { ItemBroadcast },
})
export default class InvoiceReceivedBroadcast extends Vue {
  obPusher = usePusher();
  obInvoiceReceivedChannel: Channel | null = null;
  arMessageList: string[] = [];

  get user() {
    return AuthModule.user;
  }

  get company() {
    return AppModule.company;
  }

  onGetReceivedInvoiceMessage(obData: InvoiceSignedChannel) {
    if (
      !obData.company_id ||
      (obData.user_id && this.user.id !== obData.user_id) ||
      obData.company_id !== this.company.id ||
      !obData.message
    ) {
      return;
    }

    this.arMessageList.push(obData.message);
    delay(this.onCloseMessage, 3000);
  }

  onCloseMessage() {
    this.arMessageList.shift();
  }

  created() {
    this.obInvoiceReceivedChannel = this.obPusher.channel(
      "invoice",
      "received",
      this.onGetReceivedInvoiceMessage
    );
  }

  beforeDestroy() {
    if (this.obInvoiceReceivedChannel) {
      this.obInvoiceReceivedChannel.disconnect();
    }
  }
}
</script>
