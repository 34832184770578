<template>
  <v-list v-auto-animate dense>
    <accounting-entry-broadcast />
    <invoice-signing-broadcast />
    <invoice-received-broadcast />

    <nav-group :items="items" root>
      <template #item-releases-list="{ item }">
        <release-menu-item :item="item" />
      </template>

      <template #item-account-account="{ item }">
        <account-menu-item :item="item" />
      </template>
    </nav-group>
  </v-list>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { MenuItem } from "@/config/mainMenu";
import NavGroup from "@/layout/components/main/drawer/NavGroup.vue";
import ReleaseMenuItem from "@/modules/releases/components/ReleaseMenuItem.vue";
import useMenu from "@/composables/menu";
import AccountMenuItem from "@/modules/account/components/AccountMenuItem.vue";
import { LocationModule } from "@/store/location";
import { AppModule } from "@/store/app";
import AccountingEntryBroadcast from "@/components/common/broadcast/AccountingEntryBroadcast.vue";
import InvoiceSigningBroadcast from "@/components/common/broadcast/InvoiceSigningBroadcast.vue";
import InvoiceReceivedBroadcast from "@/components/common/broadcast/InvoiceReceivedBroadcast.vue";

@Component({
  components: {
    InvoiceReceivedBroadcast,
    InvoiceSigningBroadcast,
    AccountingEntryBroadcast,
    AccountMenuItem,
    ReleaseMenuItem,
    NavGroup,
  },
})
export default class BottomNav extends Vue {
  items: MenuItem[] = [];
  arNavItems: MenuItem[] = [
    {
      title: "my.account.data",
      to: "account.account",
    },
    {
      title: "releases",
      to: "releases.list",
    },
  ];
  obMenu = useMenu(this.arNavItems, this.$router);

  onSetItems() {
    this.items = this.obMenu.setItems();
  }

  async onPreload() {
    await AppModule.loadCategories();
    await LocationModule.loadCountries();
    await LocationModule.loadStates();
    await LocationModule.loadTowns();
  }

  async mounted() {
    this.onSetItems();
    await this.onPreload();
  }
}
</script>
