import store from "@/store";
import {
  CfeCode,
  CfeCodeCollection,
  CurrencyRates,
  InvoiceType,
  InvoiceTypeCollection,
  Module as ModuleModel,
  ModuleCollection,
  Settings,
} from "@planetadeleste/vue-mc-gw";
import { isNil, reduce } from "lodash";
import dayjs from "dayjs";
import { Action, getModule, Module, VuexModule } from "vuex-module-decorators";
import { AppModule } from "./app";

export interface MaxInvoicesUI {
  date: string;
  value: number;
}

const getClosestMaxInvoiceUI = (arValue: MaxInvoicesUI[]) => {
  const now = dayjs();
  return reduce(arValue, (obResult: MaxInvoicesUI, obItem: MaxInvoicesUI) => {
    if (isNil(arValue)) {
      return obItem;
    }

    return now.isSameOrAfter(obItem.date) ? obItem : obResult;
  });
};

@Module({ dynamic: true, store, name: "config", namespaced: true })
export default class ConfigStore extends VuexModule {
  private _company_currency_rates: CurrencyRates = new CurrencyRates();
  private _invoice_types: InvoiceTypeCollection = new InvoiceTypeCollection();
  private _company_cfecodes: CfeCodeCollection = new CfeCodeCollection();
  private _company_modules: ModuleCollection = new ModuleCollection();

  private _settings: Settings = new Settings();

  get settings() {
    return this._settings;
  }

  get maxInvoiceUI() {
    const obItem = getClosestMaxInvoiceUI(this._settings.invoice_ui);
    return obItem ? obItem.value : null;
  }

  get maxUIAmount() {
    if (!this.maxInvoiceUI || !this._settings.currency_ui) {
      return 0;
    }

    return this.maxInvoiceUI * this._settings.currency_ui;
  }

  get companyCurrencyRates() {
    return this._company_currency_rates;
  }

  get companyCurrencyIdList(): number[] {
    return this._company_currency_rates.map("currency_id");
  }

  get companyInvoiceTypes(): InvoiceType[] {
    return this._invoice_types.getModels();
  }

  get companyCfeCodes(): CfeCode[] {
    return this._company_cfecodes.getModels();
  }

  get companyModules(): ModuleModel[] {
    return this._company_modules.getModels();
  }

  get companyModuleCodes(): string[] {
    return this._company_modules.map("code");
  }

  get showCompanyBalance(): boolean {
    return this._settings.get("show_company_balance", false);
  }

  @Action
  async load() {
    await this._settings.fetch();
  }

  @Action
  async loadCompanyCurrencyRates(bForce = false) {
    if (!AppModule.company || !AppModule.company.id) {
      return;
    }

    if (this._company_currency_rates.length && !bForce) {
      return;
    }

    this._company_currency_rates.page(1).clear();
    // @ts-ignore
    await this._company_currency_rates
      .filterBy({ companyGrouped: AppModule.company.id })
      .fetch();
  }

  @Action
  async loadCompanyInvoiceTypes(bForce = false): Promise<void> {
    if (
      !AppModule.company ||
      !AppModule.company.id ||
      (this._invoice_types.length && !bForce)
    ) {
      return;
    }

    const obResponse = await AppModule.company.getInvoiceTypes();
    this._invoice_types.clear();
    this._invoice_types.add(obResponse.getData().data);
  }

  @Action
  async loadCompanyCfeCodes(bForce = false): Promise<void> {
    if (
      !AppModule.company ||
      !AppModule.company.id ||
      (this._company_cfecodes.length && !bForce)
    ) {
      return;
    }

    const obResponse = await AppModule.company.getCfeCodes();
    this._company_cfecodes.clear();
    this._company_cfecodes.add(obResponse.getData().data);
  }

  @Action
  async loadCompanyModules(bForce = false): Promise<void> {
    if (
      !AppModule.company ||
      !AppModule.company.id ||
      (this._company_modules.length && !bForce)
    ) {
      return;
    }

    const obResponse = await AppModule.company.getModules();
    this._company_modules.clear();
    this._company_modules.add(obResponse.getData().data);
  }
}

export const ConfigModule = getModule(ConfigStore);
