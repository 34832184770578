import { Action, getModule, Module, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import {
  Countries,
  Country,
  State,
  StateData,
  States,
  Towns,
} from "@planetadeleste/vue-mc-location";
import { Response } from "vue-mc";

@Module({ dynamic: true, store, name: "location", namespaced: true })
class Location extends VuexModule {
  private _countries: Countries = new Countries();

  get countries() {
    return this._countries;
  }

  private _states: States = new States();

  get states() {
    return this._states;
  }

  private _towns: Towns = new Towns();

  get towns() {
    return this._towns;
  }

  get defaultCountry() {
    return this._countries.find({ is_default: true });
  }

  get defaultState() {
    return this._states.find({ is_default: true });
  }

  @Action
  async loadCountries() {
    // @ts-ignore
    await this._countries.filterBy({ active: 1 }).fetch();
  }

  @Action
  async loadStates(iCountryID?: number) {
    const obCountry = (
      iCountryID
        ? this._countries.find({ id: iCountryID })
        : this.defaultCountry
    ) as Country | undefined;

    this._states.clear();

    if (obCountry) {
      if (obCountry.states.length) {
        this._states.add(obCountry.states);
      } else {
        obCountry.getStates().then((response: Response<StateData[]>) => {
          if (response) {
            const obData = response.getData();
            this._states.add(obData.data || obData);
          }
        });
      }
    }
  }

  @Action
  async loadTowns(iStateID?: number) {
    const obState = iStateID
      ? this._states.find({ id: iStateID })
      : this.defaultState;

    if (obState && obState instanceof State) {
      obState.getTowns().then((response) => {
        if (response) {
          const obData = response.getData();
          this._towns.clear();
          this._towns.add(obData.data || obData);
        }
      });
    }
  }
}

export const LocationModule = getModule(Location);
