import { has, set } from "lodash";
import Vue from "vue";
import Toast, { POSITION, TYPE } from "vue-toastification";
import "vue-toastification/dist/index.css";
import {
  PluginOptions,
  ToastOptionsAndRequiredContent,
} from "vue-toastification/dist/types/src/types";

const filterToasts = (toasts: ToastOptionsAndRequiredContent[]) => {
  // Keep track of existing types
  const types = {};
  return toasts.reduce((aggToasts: ToastOptionsAndRequiredContent[], toast) => {
    // Check if type was not seen before
    if (toast.type && !has(types, toast.type)) {
      aggToasts.push(toast);
      set(types, toast.type, true);
    }
    return aggToasts;
  }, []);
};

const filterBeforeCreate = (
  toast: ToastOptionsAndRequiredContent,
  toasts: ToastOptionsAndRequiredContent[]
) => {
  if (toasts.filter((t) => t.type === toast.type).length !== 0) {
    // Returning false discards the toast
    return false;
  }
  // You can modify the toast if you want
  return toast;
};

const options: PluginOptions = {
  position: POSITION.TOP_CENTER,
  hideProgressBar: true,
  maxToasts: 3,
  filterToasts,
  filterBeforeCreate,
  toastDefaults: {
    [TYPE.ERROR]: {
      timeout: false,
      closeOnClick: false,
    },
  },
};

Vue.use(Toast, options);
