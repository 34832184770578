<template>
  <v-list
    :class="css"
    :two-line="hasSubtitle"
    class="avatar-text pa-0"
    color="transparent"
    dense
    rounded
  >
    <template v-for="(item, idx) in itemList">
      <v-list-item
        :key="`name-with-avatar-item-${idx}`"
        :color="color"
        :link="!!to"
        :to="to"
        class="mb-2"
        dense
        v-bind="$attrs"
        v-on="$listeners"
      >
        <v-list-item-avatar
          v-if="!(onlyImgAvatar && !item.path)"
          :class="cssAvatar"
          :color="item.avatarColor"
          :size="avatarSize"
          class="my-0 py-0"
        >
          <slot name="avatar" />
          <template v-if="!hasAvatar">
            <v-img v-if="item.path" :src="item.path" />
            <component
              :is="item.iconComp"
              v-else-if="item.iconComp"
              :color="item.iconColor"
              size="1.5em"
              v-bind="item.iconProps"
            />
            <v-icon v-else :color="item.iconColor">{{ item.icon }}</v-icon>
          </template>
        </v-list-item-avatar>

        <v-list-item-icon v-if="hasIcon">
          <slot name="icon" />
        </v-list-item-icon>

        <v-list-item-content :class="cssLabel">
          <v-list-item-title class="ma-0" v-html="item.name" />
          <v-list-item-subtitle>
            <slot name="subtitle">
              {{ subtitle }}
            </slot>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Location } from "vue-router";
import { assign, get, isEmpty, isNil, map } from "lodash";

export interface NameWithAvatarItemData extends Record<string, any> {
  name: string;
  path?: string;
  icon?: string;
  iconColor?: string;
  iconComp?: string | null;
  iconProps?: Record<string, any>;
  avatarColor?: string;
}

/**
 * Display name with avatar/icon
 */
@Component
export default class NameWithAvatar extends Vue {
  /**
   * Name to display at right side of avatar/icon
   * @type {string}
   */
  @Prop(String) readonly name!: string;

  /**
   * String to display below of name
   * @type {string}
   */
  @Prop(String) readonly subtitle!: string;

  /**
   * Avatar src path
   * @type {string}
   */
  @Prop(String) readonly path!: string;

  /**
   * SVG icon component name (without icon- prefix)
   * @type {string}
   */
  @Prop(String) readonly svgIcon!: string;

  /**
   * Icon name for `v-icon` component. Use without not svg-icon
   * @type {string}
   */
  @Prop({ type: String, default: "mdi-camera" }) readonly icon!: string;

  /**
   * `v-avatar` color
   * @type {string}
   */
  @Prop({ type: String, default: "grey" }) readonly avatarColor!: string;

  /**
   * `v-avatar` size
   * @type {string|number}
   */
  @Prop({ type: [String, Number], default: 40 }) readonly avatarSize!: string;

  /**
   * Icon color
   * @type {string}
   */
  @Prop({ type: String, default: "white" }) readonly iconColor!: string;

  /**
   * Item color
   * @type {string}
   */
  @Prop({ type: String, default: "transparent" }) readonly color!: string;

  /**
   * Add font-weight-bold to name label
   * @type {boolean}
   */
  @Prop({ type: Boolean, default: true }) readonly bold!: boolean;

  /**
   * Add font-weight-normal to name label
   * @type {boolean}
   */
  @Prop(Boolean) readonly light!: boolean;

  /**
   * Set props to svg-icon component
   * @type {Record<string, any>}
   */
  @Prop([Array, Object]) readonly iconProps!: Record<string, any>;

  /**
   * Set route location
   * @type {Location}
   */
  @Prop(Object) readonly to!: Location;

  @Prop(Array) readonly items!: NameWithAvatarItemData[];

  /**
   * Fill height of parent element
   * @type {Boolean}
   */
  @Prop(Boolean) readonly fillHeight!: boolean;

  /**
   * Hide avatar on mobile devices
   * @type {Boolean}
   */
  @Prop(Boolean) readonly hideAvatarOnMobile!: boolean;

  /**
   * Hide avatar if item does not have path (image)
   * @type {Boolean}
   */
  @Prop(Boolean) readonly onlyImgAvatar!: boolean;

  /**
   * Hide label on mobile devices
   * @type {Boolean}
   */
  @Prop(Boolean) readonly hideLabelOnMobile!: boolean;

  get itemList() {
    return !isNil(this.items) && !isEmpty(this.items)
      ? map(this.items, this.mapItem)
      : [this.defaultItem()];
  }

  get iconComp() {
    return this.svgIcon ? `icon-${this.svgIcon}` : null;
  }

  get labelWeight() {
    return `font-weight-${this.bold && !this.light ? "bold" : "normal"}`;
  }

  get hasSubtitle() {
    return !!get(this.$slots, "subtitle");
  }

  get hasAvatar() {
    return !!get(this.$slots, "avatar");
  }

  get hasIcon() {
    return !!get(this.$slots, "icon");
  }

  get css() {
    return {
      "fill-height": this.fillHeight,
    };
  }

  get cssAvatar() {
    return {
      "d-none d-md-inline-flex": this.hideAvatarOnMobile,
    };
  }

  get cssLabel() {
    return {
      "d-none d-md-inline-flex": this.hideLabelOnMobile,
      "pa-0": true,
    };
  }

  mapItem(obItem: Record<string, any>) {
    return assign({}, this.defaultItem(), obItem);
  }

  defaultItem(): NameWithAvatarItemData {
    return {
      name: this.name,
      path: this.path,
      icon: this.icon,
      iconColor: this.iconColor,
      iconComp: this.iconComp,
      avatarColor: this.avatarColor,
    };
  }
}
</script>
