<template>
  <nav-item :item="item">
    <template #title="{ translatedTitle }">
      <v-badge
        :content="nItems"
        :value="nItems > 0"
        color="primary"
        offset-x="-10"
        overlap
      >
        {{ translatedTitle }}
      </v-badge>
    </template>
  </nav-item>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { MenuItem } from "@/config/mainMenu";
import NavItem from "@/layout/components/main/drawer/NavItem.vue";
import useInvoice from "@/composables/invoice";
import { EventBus } from "@/services/event-bus";

type InvoiceType =
  | "saved"
  | "emmited"
  | "debt"
  | "debt.saved"
  | "received.unprocessed";

@Component({
  components: { NavItem },
})
export default class InvoiceSavedMenuItem extends Vue {
  @Prop(Object) readonly item!: MenuItem;
  @Prop(String) readonly invoiceType!: InvoiceType;
  nItems = 0;

  get obFilterData(): Record<string, any> {
    if (!this.invoiceType) {
      return {};
    }

    switch (this.invoiceType) {
      case "saved":
        return { unsigned: 1, movementTypeCode: [1, 2, 5, 6] };
      case "debt.saved":
        return { unsigned: 1, movementTypeCode: [7] };
      case "received.unprocessed":
        return { received: 1, notProcessed: 1 };
    }

    return {};
  }

  async onCount() {
    const { count } = useInvoice();
    const obResponse = await count(this.obFilterData);

    if (obResponse) {
      this.nItems = obResponse.getData().data.count;
    }
  }

  mounted() {
    this.onCount();
  }

  created() {
    EventBus.on("invoice.after.save", this.onCount);
    EventBus.on("invoice.signed", this.onCount);
    EventBus.on("invoice.received.after.processed", this.onCount);
  }

  beforeDestroy() {
    EventBus.off("invoice.after.save");
    EventBus.off("invoice.signed");
    EventBus.off("invoice.received.after.processed");
  }
}
</script>
