import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import VueI18n from "vue-i18n";
import { isEmpty } from "lodash";
import { TYPE } from "vue-toastification";

export interface FlashObject {
  variant: TYPE;
  message: string | VueI18n.TranslateResult;
}

@Module({
  name: "flash",
  dynamic: true,
  store: store,
  namespaced: true,
})
export default class Flash extends VuexModule {
  private _message: string | VueI18n.TranslateResult = "";
  private _showWelcome = false;

  private _variant: TYPE = TYPE.DEFAULT;

  get variant(): TYPE {
    return this._variant;
  }

  get flashed(): boolean {
    return !isEmpty(this._message);
  }

  get flashMessage(): string {
    return this._message as string;
  }

  get displayWelcomeMessage() {
    return this._showWelcome;
  }

  @Action({ commit: "setShowWelcome" })
  showWelcome() {
    return true;
  }

  @Action({ commit: "setShowWelcome" })
  hideWelcome() {
    return false;
  }

  @Action({ commit: "SET_FLASH" })
  message(message: FlashObject): FlashObject {
    return message;
  }

  @Action
  success(message: string | VueI18n.TranslateResult): void {
    this.message({ message: message, variant: TYPE.SUCCESS });
  }

  @Action
  error(message: string | VueI18n.TranslateResult): void {
    this.message({ message: message, variant: TYPE.ERROR });
  }

  @Action
  warning(message: string | VueI18n.TranslateResult): void {
    this.message({ message: message, variant: TYPE.WARNING });
  }

  @Action
  info(message: string | VueI18n.TranslateResult): void {
    this.message({ message: message, variant: TYPE.INFO });
  }

  @Action
  closeFlash(): void {
    this.CLEAR_FLASH();
  }

  @Mutation
  private SET_FLASH(flash: FlashObject): void {
    this._variant = flash.variant || TYPE.DEFAULT;
    this._message = flash.message;
  }

  @Mutation
  private CLEAR_FLASH(): void {
    this._message = "";
  }

  @Mutation
  private setShowWelcome(bVar: boolean) {
    this._showWelcome = bVar;
  }
}

export const FlashModule = getModule(Flash);
