export default [
  // - Companies
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/companies",
    name: "companies.index",
  },
  { methods: ["POST"], uri: "api/v1/gw/companies", name: "companies.store" },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/companies/list",
    name: "companies.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/companies/{id}",
    name: "companies.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/gw/companies/{id}",
    name: "companies.update",
  },
  {
    methods: ["POST", "PUT", "PATCH"],
    uri: "api/v1/gw/companies/attach/{id}",
    name: "companies.attach",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/companies/{id}",
    name: "companies.destroy",
  },

  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/companies/balance/{id?}",
    name: "companies.balance",
  },

  // Company - Payment methods
  {
    methods: ["POST"],
    uri: "api/v1/gw/companies/payment_methods",
    name: "companies.payment_methods.store",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/companies/payment_methods",
    name: "companies.payment_methods.destroy",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/companies/payment_methods/{id?}",
    name: "companies.payment_methods.list",
  },

  // Company - Invoice types
  {
    methods: ["POST"],
    uri: "api/v1/gw/companies/invoice_types",
    name: "companies.invoice_types.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/companies/invoice_types/{id?}",
    name: "companies.invoice_types.list",
  },

  // Company - CFE codes
  {
    methods: ["POST"],
    uri: "api/v1/gw/companies/cfecodes",
    name: "companies.cfecodes.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/companies/cfecodes/{id?}",
    name: "companies.cfecodes.list",
  },

  // Company - Tax types
  {
    methods: ["POST"],
    uri: "api/v1/gw/companies/taxtypes",
    name: "companies.taxtypes.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/companies/taxtypes/{id?}",
    name: "companies.taxtypes.list",
  },

  // Company - Modules
  {
    methods: ["POST"],
    uri: "api/v1/gw/companies/modules",
    name: "companies.modules.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/gw/companies/modules/{id?}",
    name: "companies.modules.list",
  },

  // Company - Currency
  {
    methods: ["DELETE"],
    uri: "api/v1/gw/companies/currency",
    name: "companies.currency.destroy",
  },

  // Company - Update settings
  {
    methods: ["POST"],
    uri: "api/v1/gw/companies/settings/{id?}",
    name: "companies.settings.update",
  },
];
