<template>
  <div v-frag>
    <item-broadcast
      v-for="item in arBroadcastData"
      :key="`accounting.entry.batch.${item.batch_id}`"
      :hide-progress="item.progress >= 100"
      :message="$t('accountingentryinvoices')"
      :progress="item.progress"
    >
      <template #subtitle>
        {{ $t(item.progress >= 100 ? "completed" : "processing") }}
        {{ $t("progress.of", { progress: item.progress, total: item.total }) }}
      </template>
      <template #icon>
        <icon-checkmark-done-circle-outline
          v-if="item.progress >= 100"
          class="green--text"
          size="24px"
        />
      </template>
    </item-broadcast>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { Channel } from "pusher-js";
import { AccountingEntryGenerateProgressChannel } from "@/types/broadcast";
import BroadcastMixin from "@/mixins/BroadcastMixin";
import { delay, find, findIndex } from "lodash";
import { EventBus } from "@/services/event-bus";
import { TYPE } from "vue-toastification";
import ItemBroadcast from "@/components/common/broadcast/ItemBroadcast.vue";

@Component({
  components: { ItemBroadcast },
})
export default class AccountingEntryBroadcast extends Mixins(BroadcastMixin) {
  arBroadcastData: AccountingEntryGenerateProgressChannel[] = [];
  obProgressChannel: Channel | null = null;

  onProgress(obData: AccountingEntryGenerateProgressChannel) {
    if (this.user.id !== obData.user_id) {
      return;
    }

    if (obData.message) {
      this.$toast(obData.message, { type: TYPE.ERROR });
    }

    const obBatchData = find(this.arBroadcastData, {
      batch_id: obData.batch_id,
    }) as AccountingEntryGenerateProgressChannel | undefined;

    if (!obBatchData) {
      if (!this.arBroadcastData.length) {
        this.$emit("broadcast:start", this.broadcastKey);
      }

      this.arBroadcastData.push(obData);
      return;
    }

    if (obData.progress > obBatchData.progress && obBatchData.progress < 100) {
      obBatchData.progress = obData.progress;
    }

    const idx = this.getIndex(obBatchData.batch_id);

    if (idx < 0) {
      return;
    }

    this.arBroadcastData.splice(idx, 1, obBatchData);

    if (obBatchData.progress >= 100) {
      this.onFinish(obBatchData.batch_id);
      return;
    }
  }

  onFinish(batchId: number) {
    EventBus.emit(`${this.broadcastKey}.finish`);

    if (this.$route.name === "accountingentryinvoices.list") {
      EventBus.emit("reload.index");
    }

    const idx = this.getIndex(batchId);

    if (idx >= 0) {
      delay(() => {
        this.arBroadcastData.splice(idx, 1);

        if (!this.arBroadcastData.length) {
          this.$emit("broadcast:end", this.broadcastKey);
        }
      }, 5000);
    }
  }

  getIndex(batch_id: number) {
    return findIndex(this.arBroadcastData, { batch_id });
  }

  created(): void {
    this.channel = "accountingentry";
    this.event = "progress";
    this.obProgressChannel = this.obPusher.channel(
      this.channel,
      this.event,
      this.onProgress
    );
  }

  beforeDestroy() {
    if (this.obProgressChannel) {
      this.obProgressChannel.disconnect();
    }
  }
}
</script>
