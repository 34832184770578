import { ConfigModule } from "@/store/config";
import { castArray, concat, filter, forEach, pick, startsWith } from "lodash";
import { ModulesModule } from "@/store/modules";
import { RouteNamedMap } from "@/types/typed-router";

type ModuleCode =
  | "accounting"
  | "accounting.code.customers"
  | "accounting.code.providers"
  | "accounting.entries"
  | "contracts"
  | "invoices.debt"
  | "invoices.paid"
  | "invoices.purchased"
  | "invoices.receipt"
  | "invoices.sales"
  | "invoices.sales.exp"
  | "stocks";

/**
 * Define all modules routes
 */
export const allRoutesInModules = (): Partial<
  Record<ModuleCode, Array<keyof RouteNamedMap>>
> => {
  const arModules: ModuleCode[] = ModulesModule.codes as ModuleCode[];
  const obModuleRoutes: Partial<
    Record<ModuleCode, Array<keyof RouteNamedMap>>
  > = {};

  forEach(arModules, (sModule) => {
    switch (sModule) {
      case "accounting":
        obModuleRoutes[sModule] = [
          "accounts.list",
          "accounttaxes.list",
          "accountreceipts.list",
          "accountconfigs.list",
          "accountingentries.list",
          "accountingentryinvoices.list",
          "invoices.received.unprocessed",
        ];
        break;

      case "accounting.entries":
        obModuleRoutes[sModule] = [
          "accounts.list",
          "accounttaxes.list",
          "accountreceipts.list",
          "accountconfigs.list",
          "accountingentries.list",
          "accountingentryinvoices.list",
        ];
        break;

      case "contracts":
        obModuleRoutes[sModule] = ["contracts.list"];
        break;

      case "invoices.debt":
        obModuleRoutes[sModule] = [
          "invoices.debt.emitted",
          "invoices.debt.saved",
          "invoices.debt.received",
        ];
        break;

      case "invoices.paid":
        obModuleRoutes[sModule] = [
          "invoices.receipt.emitted",
          "invoices.receipt.saved",
          "invoices.receipt.create",
        ];
        break;

      case "invoices.purchased":
        obModuleRoutes[sModule] = [
          "invoices.received.received",
          "invoices.received.list",
          "invoices.received.unprocessed",
          "invoices.received.view",
        ];
        break;

      case "invoices.receipt":
        obModuleRoutes[sModule] = ["invoices.emitted", "invoices.saved"];
        break;

      case "invoices.sales":
        obModuleRoutes[sModule] = ["invoices.emitted", "invoices.saved"];
        break;

      case "invoices.sales.exp":
        obModuleRoutes[sModule] = ["invoices.emitted", "invoices.saved"];
        break;

      case "stocks":
        obModuleRoutes[sModule] = ["warehouses.list", "warehouses.create"];
        break;
    }
  });

  return obModuleRoutes;
};

/**
 * Get all routes names used by modules
 *
 * @param {ModuleCode[]} arModules Filter by modules
 * @returns {string[]}
 */
export const allModuleRoutes = (
  arModules?: ModuleCode[]
): Array<keyof RouteNamedMap> => {
  let arRouteList: Array<keyof RouteNamedMap> = [];
  const obModules =
    arModules && arModules.length
      ? pick(allRoutesInModules(), arModules)
      : allRoutesInModules();

  forEach(obModules, (arRoutes) => {
    // @ts-ignore
    arRouteList = concat(arRouteList, arRoutes);
  });

  return arRouteList;
};

/**
 * Get route access for current company
 *
 * @param sRouteName
 * @returns {boolean}
 */
export const canRouteAccess = (sRouteName: keyof RouteNamedMap): boolean => {
  if (!allModuleRoutes().includes(sRouteName)) {
    return true;
  }

  let arModules: ModuleCode[] = ConfigModule.companyModuleCodes as ModuleCode[];

  if (!arModules.includes("accounting")) {
    arModules = filter(
      arModules,
      (sModule) => !startsWith(sModule, "accounting")
    );
  }

  if (sRouteName === "invoices.received.unprocessed") {
    return (
      arModules.includes("accounting") &&
      arModules.includes("invoices.purchased")
    );
  }

  return allModuleRoutes(arModules).includes(sRouteName);
};

/**
 * Get module acces for current company
 *
 * @param {ModuleCode} sModule
 * @param {Boolean} some
 * @returns {boolean}
 */
export const canModuleAccess = (
  sModule: ModuleCode | ModuleCode[],
  some: boolean = false
): boolean => {
  const arModules = castArray(sModule);

  return some
    ? arModules.some((v) => ConfigModule.companyModuleCodes.includes(v))
    : arModules.every((v) => ConfigModule.companyModuleCodes.includes(v));
};
