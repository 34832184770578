<template>
  <item-broadcast :message="sMessage" />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Channel } from "pusher-js";
import { usePusher } from "@/services/pusher";
import { InvoiceCreatedChannel } from "@/types/broadcast";
import { delay } from "lodash";
import { AuthModule } from "@/store/auth";
import ItemBroadcast from "@/components/common/broadcast/ItemBroadcast.vue";
import { EventBus } from "@/services/event-bus";

@Component({
  components: { ItemBroadcast },
})
export default class InvoiceSigningBroadcast extends Vue {
  obInvoiceSigningChannel: Channel | null = null;
  obInvoiceSignedChannel: Channel | null = null;
  obInvoiceUnsignedChannel: Channel | null = null;
  obPusher = usePusher();
  sMessage: string | null | undefined = null;

  get user() {
    return AuthModule.user;
  }

  onSigning(obData: InvoiceCreatedChannel) {
    if (this.user.id !== obData.user_id) {
      return;
    }

    this.sMessage = obData.message;
  }

  onSigned(obData: InvoiceCreatedChannel) {
    EventBus.emit("invoice.signed", obData.invoice_id);
    if (this.user.id !== obData.user_id) {
      return;
    }

    this.sMessage = obData.message;
    delay(this.onCloseMessage, 3000);
  }

  onUnSigned(obData: InvoiceCreatedChannel) {
    if (this.user.id !== obData.user_id) {
      return;
    }

    this.onCloseMessage();
  }

  onCloseMessage() {
    this.sMessage = null;
  }

  created() {
    this.obInvoiceSigningChannel = this.obPusher.channel(
      "invoice",
      "signing",
      this.onSigning
    );
    this.obInvoiceSignedChannel = this.obPusher.channel(
      "invoice",
      "signed",
      this.onSigned
    );

    this.obInvoiceUnsignedChannel = this.obPusher.channel(
      "invoice",
      "unsigned",
      this.onUnSigned
    );
  }

  beforeDestroy() {
    if (this.obInvoiceSigningChannel) {
      this.obInvoiceSigningChannel.disconnect();
    }

    if (this.obInvoiceSignedChannel) {
      this.obInvoiceSignedChannel.disconnect();
    }

    if (this.obInvoiceUnsignedChannel) {
      this.obInvoiceUnsignedChannel.disconnect();
    }
  }
}
</script>
