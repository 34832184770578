<template>
  <name-with-avatar
    v-if="currency"
    :avatar-size="size"
    :name="currency.name"
    hide-label-on-mobile
    v-on="$listeners"
    v-bind="$attrs"
  >
    <template #avatar>
      <svg-icon :data="getFlag(currency.code)" original size="100%" />
    </template>
  </name-with-avatar>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import NameWithAvatar from "@/components/common/NameWithAvatar.vue";
import { CurrencyData } from "@planetadeleste/vue-mc-shopaholic";
import { AppModule } from "@/store/app";

@Component({
  components: { NameWithAvatar },
})
export default class CurrencyPreview extends Vue {
  @Prop(Object) readonly item!: CurrencyData;
  @Prop([Number, String]) readonly currencyId!: number | string;
  @Prop({ type: [Number, String], default: 32 }) readonly size!:
    | number
    | string;

  get currency() {
    if (!this.item && !this.currencyId) {
      return undefined;
    }

    return this.item || AppModule.currencies.find({ id: this.currencyId });
  }

  getFlag(sCode: string) {
    return require(`@icon/flags/${sCode.toLowerCase()}.svg`);
  }
}
</script>
