const path = require("path-browserify");

import Vue from "vue";
import { kebabCase } from "lodash";

import autoAnimate from "@formkit/auto-animate";
import SvgIcon from "@bit/planetadeleste.gui.ui.svg-icon";
import TBreadcrumbs from "@bit/planetadeleste.gui.ui.breadcrumbs";

import ImagesUpload from "@/components/form/image-upload/ImagesUpload.vue";
import DataTable from "@/components/common/data-table/DataTable.vue";
import Sheet from "@/components/common/Sheet.vue";
import ModuleContainer from "@/components/common/ModuleContainer.vue";
import ModuleActions from "@/components/common/ModuleActions.vue";

import FormObserver from "@/components/form/FormObserver.vue";
import GwFormObserver from "@/components/form/GwFormObserver.vue";
import Text from "@/components/form/fields/Text.vue";
import Password from "@/components/form/fields/Password.vue";
import Passwords from "@/components/form/fields/Passwords.vue";
import Email from "@/components/form/fields/Email.vue";
import Agree from "@/components/form/fields/Agree.vue";
import DateField from "@/components/form/fields/DateField.vue";
import Label from "@/components/form/fields/Label.vue";
import Hint from "@/components/form/fields/Hint.vue";

import frag from "vue-frag";

Vue.directive("frag", frag);
Vue.directive("auto-animate", {
  inserted: function (el, binding) {
    autoAnimate(el, binding.value);
  },
});

Vue.component("svg-icon", SvgIcon);
Vue.component("images-upload", ImagesUpload);
Vue.component("t-breadcrumbs", TBreadcrumbs);
Vue.component("data-table", DataTable);
Vue.component("sheet", Sheet);
Vue.component("module-container", ModuleContainer);
Vue.component("module-actions", ModuleActions);

// Form Fields
Vue.component("form-observer", FormObserver);
Vue.component("gw-form-observer", GwFormObserver);
Vue.component("form-field-text", Text);
Vue.component("form-field-password", Password);
Vue.component("form-field-passwords", Passwords);
Vue.component("form-field-email", Email);
Vue.component("form-field-agree", Agree);
Vue.component("form-field-date", DateField);
Vue.component("form-field-label", Label);
Vue.component("form-field-hint", Hint);

// Load icons components
const ComponentContext = require.context(
  "@/components/icons",
  false,
  /\.vue$/i,
  "lazy"
);
ComponentContext.keys().forEach((componentFilePath) => {
  let componentName = path.basename(
    componentFilePath,
    path.extname(componentFilePath)
  );
  componentName = kebabCase(componentName);
  Vue.component(componentName, () => ComponentContext(componentFilePath));
});
