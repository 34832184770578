import mitt from "mitt";
import {
  CurrencyRates,
  CustomerData,
  DiscountData,
  InvoiceData,
  InvoiceMovementTypeData,
  PaymentMethod,
} from "@planetadeleste/vue-mc-gw";
import { FilterData } from "@/modules/reports/components/ReportFilters.vue";
import { DataTableHeader } from "@/mixins/DataTableMixin";

type Events = {
  "reload.form": void;
  "reload.index": void;

  // APP STORE
  "app.branch.selected"?: number;
  "app.company.selected": number;
  "filters.change": string;

  // COMPANY
  "company.reload.balance": void;
  "company.update.payment_methods": void;
  "company.update.modules": number;
  "get.company.currencies": CurrencyRates;

  // CUSTOMER
  "open.customer.form.dialog"?: Record<string, boolean>;

  // INVOICES
  "before.add.global.reference": void;
  "invoice.add.discounts": DiscountData[];
  "invoice.apply.discounts": void;
  "invoice.change.price"?: boolean;
  "invoice.currency.rate.changed": (number | string | null)[];
  "invoice.customer.changed": CustomerData;
  "invoice.movement_type.changed": InvoiceMovementTypeData;
  "invoice.paymentMethodConfig.dialog.closed": PaymentMethod;
  "invoice.price_list.changed": number;
  "invoice.product.changed"?: boolean;
  "invoice.signed": number;
  "invoice.signing": number;
  "invoice.unsigned": number;
  "invoice.print": Partial<InvoiceData>;
  "invoices.list.apply.headers": DataTableHeader[];
  "invoice.received.after.processed": InvoiceData | undefined;

  // REPORT
  "report.before.apply": FilterData;
} & Record<string, any>;
export const EventBus = mitt<Events>();
