<template>
  <v-app-bar app clipped-left dark fixed flat>
    <v-app-bar-nav-icon @click="setDrawer">
      <icon-menu outlined size="1.5rem" />
    </v-app-bar-nav-icon>
    <v-toolbar-title style="min-width: 80px">
      <router-link :to="{ name: 'home' }">
        <v-img
          class="d-md-none"
          contain
          max-height="45"
          max-width="100%"
          position="left center"
          src="@image/logo-sm.png"
        />
        <v-img
          class="d-none d-md-block"
          contain
          max-height="45"
          max-width="100%"
          position="left center"
          src="@image/logo.png"
        />
      </router-link>
    </v-toolbar-title>

    <v-spacer />

    <invoice-btn-create />

    <v-divider vertical />
    <company-picker />
    <v-divider class="d-none d-md-block" vertical />
    <company-balance class="d-none d-md-block" />
    <v-divider vertical />

    <v-btn text @click="setRightDrawer">
      <profile-nav />
    </v-btn>
  </v-app-bar>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import ProfileNav from "@/layout/components/ProfileNav.vue";
import CompanyPicker from "@/modules/companies/components/CompanyPicker.vue";
import CompanyBalance from "@/modules/companies/components/CompanyBalance.vue";
import InvoiceBtnCreate from "@/modules/invoices/components/InvoiceBtnCreate.vue";

@Component({
  components: { InvoiceBtnCreate, ProfileNav, CompanyPicker, CompanyBalance },
})
export default class AppBar extends Vue {
  @Prop(Boolean) readonly drawer!: boolean;
  @Prop(Boolean) readonly rightDrawer!: boolean;

  setDrawer() {
    return this.$emit("change:drawer", !this.drawer);
  }

  setRightDrawer() {
    return this.$emit("change:right-drawer", !this.rightDrawer);
  }
}
</script>
