import Vue from "vue";
import { GwCacheModule } from "@/store/cache";
import { Callback } from "@/types/utils";
import Pusher from "pusher-js";
import CacheService from "./cache";
import { forEach, get, has, isEmpty } from "lodash";
import { route } from "./laroute";
import { Result } from "vue-mc";

export interface PusherCredentials {
  APP_KEY: string;
  APP_CLUSTER: string;
}

export interface ChannelCollection {
  channel: string;
  event: string;
  callback: Callback;
}

class PusherService {
  static _pusher: Pusher | null = null;
  static _channels: ChannelCollection[] = [];
  private cache: CacheService;

  constructor() {
    this.cache = new CacheService(this.cacheIdentifier, this.cacheKey);
    this.setCredentials();
  }

  async getCredentials() {
    let obData = this.cache.cache<PusherCredentials>();

    if (!isEmpty(obData) && has(obData, "APP_KEY")) {
      return obData;
    }

    const sUrl = route("pusher.credentials");
    const obResponse: Result<Record<string, any>> = await this.$http.get(sUrl);
    const obResponseData = obResponse.data;

    obData = {
      APP_KEY: get(obResponseData, "data.key"),
      APP_CLUSTER: get(obResponseData, "data.cluster"),
    };

    return this.cache.cache(obData);
  }

  async setCredentials() {
    if (!this.pusher) {
      const obCredentials = await this.getCredentials();

      if (!obCredentials) {
        return;
      }

      PusherService._pusher = new Pusher(obCredentials.APP_KEY, {
        cluster: obCredentials.APP_CLUSTER,
      });
    }

    if (this.channels.length) {
      forEach(this.channels, (obChannelData) => {
        this.channel(
          obChannelData.channel,
          obChannelData.event,
          obChannelData.callback
        );
      });

      PusherService._channels = [];
    }
  }

  channel(sChannel: string, sEvent: string, fnCallback: Callback) {
    if (!this.pusher) {
      this.channels.push({
        channel: sChannel,
        event: sEvent,
        callback: fnCallback,
      });

      return null;
    }

    const obChannel = this.pusher.subscribe(sChannel);
    obChannel.bind(sEvent, fnCallback);

    return obChannel;
  }

  get channels() {
    return PusherService._channels;
  }

  get pusher() {
    return PusherService._pusher;
  }

  get cacheData() {
    return GwCacheModule.cache;
  }

  get cacheKey() {
    return "PUSHER";
  }

  get cacheIdentifier() {
    return "credentials";
  }

  get $http() {
    return Vue.axios;
  }
}

export const usePusher = () => new PusherService();
