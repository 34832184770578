import { toNumeric } from "@/components/functions/toNumeric";
import { toCode } from "@/plugins/helpers";
import { LocationModule } from "@/store/location";
import { FirmData } from "@planetadeleste/vue-mc-gw/src/types";
import { StateData, TownData } from "@planetadeleste/vue-mc-location";
import { each, find, get, isArray, isEmpty, isNil, join, set } from "lodash";
import Vue from "vue";
import { Result } from "vue-mc";
import type { UcfeData } from "@planetadeleste/vue-mc-gw";

export interface UcfeDgiWSDomicilioContactoData {
  DomCtt_Val: string;
  TipoCtt_Des: string;
  TipoCtt_Id: string;
}

export interface UcfeDgiWSDomicilioContacto {
  ["WS_Domicilio.WS_DomicilioItem.Contacto"]: UcfeDgiWSDomicilioContactoData[];
}

export interface UcfeDgiWSDomFiscalLocPrincipalItem {
  CalOcup_id: string;
  Calle_Nom: string;
  Calle_id: string;
  Calocup_Des: string;
  Complementos: string[];
  Contactos: UcfeDgiWSDomicilioContacto;
  Dom_Ap_Nro: string;
  Dom_Bis_Flg: string[];
  Dom_Coment: string[];
  Dom_Err_Cod: string;
  Dom_Pst_Cod: string;
  Dom_Pta_Nro: string;
  Dpto_Id: string;
  Dpto_Nom: string;
  Loc_Id: string;
  Loc_Nom: string;
  Local_Fec_Canc: string;
  Local_Fec_Ini: string;
  Local_Nom_Fnt: string[];
  Local_Sec_Nro: string;
  TerCod_Id: string;
  Tercod_Des: string[];
  TipoDom_Des: string;
  TipoDom_Id: string;
  TipoLocal_Dsc: string;
  TipoLocal_Id: string;
}

export interface UcfeDgiWSDomFiscalLocPrincipal {
  ["WS_PersonaActEmpresarial.WS_DomFiscalLocPrincipalItem"]: UcfeDgiWSDomFiscalLocPrincipalItem;
}

export interface UcfeDgiWSPersonaActividadesItem {
  GiroCod: string;
  GiroFec_Ini: string;
  GiroNom: string;
}

export interface UcfeDgiWSPersonaActividades {
  ["WS_PersonaActEmpresarial.WS_PersonaActividadesItem"]: UcfeDgiWSPersonaActividadesItem[];
}

export interface UcfeDgiRutResponseData {
  Denominacion: string;
  DescripcionTipoEntidad: string;
  EstadoActividad: string;
  FechaInicioActivdad: string;
  NombreFantasia: string | string[];
  RUT: string;
  TipoEntidad: string;
  WS_DomFiscalLocPrincipal: UcfeDgiWSDomFiscalLocPrincipal;
  WS_PersonaActividades: UcfeDgiWSPersonaActividades;
}

export type FirmDataResponse<T> = T extends true
  ? FirmData
  : UcfeDgiRutResponseData;

class Ucfe {
  private baseUrl = "gw/ucfe";

  get $axios() {
    return Vue.axios;
  }

  async getFirmData<T extends boolean>(
    sRut: string,
    bToCompanyData?: T
  ): Promise<Result<FirmDataResponse<T>>> {
    sRut = toNumeric(sRut);
    const obResponse = await this.$axios.get(
      `${this.baseUrl}/companydata/${sRut}`
    );

    if (bToCompanyData && obResponse.data) {
      const obData: UcfeDgiRutResponseData = get(obResponse, "data.data");
      if (obData && !isEmpty(obData)) {
        const obCompanyData = await this.responseToFirmData(obData);
        set(obResponse, "data.data", obCompanyData);
      }
    }

    return obResponse.data;
  }

  async eco(iCompanyId: number) {
    const obResponse = await this.$axios.get(
      `${this.baseUrl}/eco/${iCompanyId}`
    );
    console.log(obResponse);
  }

  async validateCredentials(
    sRut: string,
    obData: Pick<UcfeData, "code" | "url" | "terminal" | "password">
  ) {
    try {
      sRut = toNumeric(sRut);
      const obCredentials = { credentials: obData };
      const obResponse = await this.$axios.post(
        `${this.baseUrl}/validate/${sRut}`,
        obCredentials
      );

      return obResponse.data.status;
    } catch (err) {
      return false;
    }
  }

  async responseToFirmData(
    obData: UcfeDgiRutResponseData
  ): Promise<FirmData | Record<string, any>> {
    const obAddress: UcfeDgiWSDomFiscalLocPrincipalItem | undefined = get(
      obData,
      "WS_DomFiscalLocPrincipal.WS_PersonaActEmpresarial.WS_DomFiscalLocPrincipalItem"
    );
    const arContacts: UcfeDgiWSDomicilioContactoData[] | undefined = get(
      obAddress,
      "Contactos.WS_Domicilio.WS_DomicilioItem.Contacto"
    );
    let sNombreFantasia = isEmpty(obData.NombreFantasia)
      ? obData.Denominacion
      : obData.NombreFantasia;
    if (isArray(sNombreFantasia)) {
      sNombreFantasia = join(sNombreFantasia, " - ");
    }

    const sAddress = obAddress ? this.parseFullAddress(obAddress) : "-";
    const obFirmData: FirmData | Record<string, any> = {
      doc_type: "rut",
      doc_id: obData.RUT,
      name: sNombreFantasia || obData.Denominacion,
      description: obData.DescripcionTipoEntidad,
      code: toCode(sNombreFantasia),
      address: sAddress,
      dgi_denominacion: obData.Denominacion,
      dgi_nombre_fantasia: sNombreFantasia,
      dgi_local_sec_nro: get(obAddress, "Local_Sec_Nro"),
      dgi_dpto_nom: get(obAddress, "Dpto_Nom"),
      dgi_dir_fiscal: sAddress,
      dgi_loc_nom: get(obAddress, "Loc_Nom"),
      cp: get(obAddress, "Dom_Pst_Cod"),
      state_text: get(obAddress, "Dpto_Nom"),
      town_text: get(obAddress, "Loc_Nom"),
    };

    const obCountry = LocationModule.defaultCountry;
    if (obCountry) {
      obFirmData.country_id = obCountry.id;
    }

    // Find State
    const obState = LocationModule.states.find((obModel: StateData) => {
      return (
        obAddress &&
        // @ts-ignore
        obModel.name.toUpperCase() == obAddress.Dpto_Nom.toUpperCase()
      );
    });

    if (obState) {
      obFirmData.state_id = obState.id;

      // Find town
      const obResponse = await obState.getTowns();
      const obTownsData = obResponse.getData().data;
      const obTownData: TownData | undefined = find(
        obTownsData,
        (obModel: TownData) => {
          return (
            obAddress &&
            // @ts-ignore
            obModel.name.toUpperCase() == obAddress.Loc_Nom.toUpperCase()
          );
        }
      ) as TownData | undefined;

      if (obTownData) {
        obFirmData.town_id = obTownData.id;
      }
    }

    // Set phone, email
    each(arContacts, (obContact: UcfeDgiWSDomicilioContactoData) => {
      switch (obContact.TipoCtt_Id) {
        case "1":
          obFirmData.email = obContact.DomCtt_Val;
          break;

        case "5":
          obFirmData.phone = obContact.DomCtt_Val;
          break;
        case "6":
          obFirmData.mobile = obContact.DomCtt_Val;
          break;
      }
    });

    return obFirmData;
  }

  parseFullAddress(obAddress: UcfeDgiWSDomFiscalLocPrincipalItem): string {
    let sAddress = obAddress.Calle_Nom;

    if (isNil(sAddress) || isEmpty(sAddress)) {
      return " - ";
    }

    if (obAddress.Dom_Pta_Nro) {
      sAddress += ` ${obAddress.Dom_Pta_Nro}`;
    }

    if (obAddress.Dom_Bis_Flg && !isEmpty(obAddress.Dom_Bis_Flg)) {
      sAddress += " Bis";
    }

    if (obAddress.Dom_Ap_Nro) {
      sAddress += ` ${obAddress.Dom_Ap_Nro}`;
    }

    return sAddress;
  }
}

export default new Ucfe();
