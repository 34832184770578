<template>
  <nav-item :item="item">
    <template #action>
      <v-progress-circular
        v-if="loading"
        color="primary"
        indeterminate
        size="16"
        width="2"
      />
      <v-list-item-action-text
        v-else-if="obRelease.id"
        v-text="obRelease.version"
      />
    </template>
  </nav-item>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { MenuItem } from "@/config/mainMenu";
import NavItem from "@/layout/components/main/drawer/NavItem.vue";
import { Release } from "@planetadeleste/vue-mc-gw";

@Component({
  components: { NavItem },
})
export default class ReleaseMenuItem extends Vue {
  @Prop(Object) readonly item!: MenuItem;
  obRelease: Release = new Release();
  loading = false;

  async mounted() {
    this.loading = true;
    const obModel = new Release();
    const obResponse = await obModel.getLatest();
    if (!!obResponse && obResponse.getData().status) {
      const obModelData = obResponse.getData().data;
      this.obRelease.set(obModelData);
      this.obRelease.sync();
    }
    this.loading = false;
  }
}
</script>
