import { assign, endsWith, forEach, get, isArray, set } from "lodash";
import { admin, PermissionRole } from "./permissions";
import { RouteConfigLocal, RouteConfigMeta } from "./routes";

export const create = (
  sName: string,
  sPath: string | null = null,
  sPage = "index",
  obMeta: RouteConfigMeta | Record<string, any> = {},
  arPermissions: PermissionRole[] | false = admin,
  arChildren: RouteConfigLocal[] | false = []
) => {
  const obDefaultMeta: RouteConfigMeta = {
    sortOrder: 999,
    module: sName,
    title: sName,
    drawer: true,
    main: true,
    iconComponent: true,
    iconProps: { icon: "bank-alt", original: true },
    breadcrumb: {
      label: sName,
      parent: "home",
    },
  };

  if (arPermissions && arPermissions.length) {
    obDefaultMeta.permissions = arPermissions;
  }

  const obRoute: RouteConfigLocal = {
    name: sName,
    path: sPath ? sPath : `/${sName.toLocaleLowerCase()}`,
    page: sPage,
    meta: assign({}, obDefaultMeta, obMeta),
  };

  if (isArray(arChildren) && !arChildren.length) {
    const arChildList = ["list", "update", "view", "create"];
    const sModule = get(obRoute, "meta.module", sName);

    forEach(arChildList, (sPage) => {
      arChildren.push(setChildren(sModule, sPage, get(obRoute, "meta")));
    });
  }

  if (arChildren && arChildren.length) {
    set(obRoute, "children", arChildren);
  }

  return obRoute;
};

export const setChildren = (
  sModule: string,
  sName: string,
  obMeta: RouteConfigMeta | Record<string, any> = {},
  sPage = "list",
  sPath = "",
  arChildren: RouteConfigLocal[] = []
): RouteConfigLocal => {
  if (sName !== "list") {
    sModule = singular(sModule);
  }

  const obDefaultMeta = {
    title: `${sName}.${sModule}`,
    breadcrumb: {
      label: `${sName}.${sModule}`,
    },
  };

  if (!sPath.length) {
    switch (sName) {
      case "list":
        sPath = "";
        break;

      case "create":
        sPath = "create";
        break;

      case "view":
      case "update":
        sPath = `${sName}/:id`;
        break;
    }
  }

  const obChildren = {
    path: sPath,
    page: sPage,
    name: sName,
    meta: assign({}, obMeta, obDefaultMeta),
  };

  if (arChildren.length) {
    set(obChildren, "children", arChildren);
  }

  return obChildren;
};

const singular = (sValue: string) => {
  if (endsWith(sValue, "ies")) {
    return `${sValue.slice(0, -3)}y`;
  }

  if (endsWith(sValue, "s")) {
    return sValue.slice(0, -1);
  }

  return sValue;
};
