<template>
  <v-row>
    <v-col :cols="cols" :lg="lg" v-if="!hidePreview">
      <div class="text-subtitle-1" v-if="!hideLabel">
        {{ $t(previewLabel) }}
      </div>
      <file-upload
        ref="pondPreviewImage"
        :name="previewValue"
        :value="previewModel"
        @input="onUpdateModel(previewValue, $event)"
      />
    </v-col>

    <v-col :cols="cols" :lg="lg" v-if="!hideImages">
      <div class="text-subtitle-1" v-if="!hideLabel">
        {{ $t(imagesLabel) }}
      </div>
      <file-upload
        ref="pondImages"
        :name="imagesValue"
        :value="imagesModel"
        multiple
        @input="onUpdateModel(imagesValue, $event)"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, Ref, Prop, VModel } from "vue-property-decorator";
import { FilePondFile } from "filepond";
import FileUpload from "./FileUpload.vue";
import { FileData, Model } from "@planetadeleste/vue-mc";
import { filter, get, kebabCase } from "lodash";

interface ElementItemWithImages {
  preview_image?: string | FilePondFile;
  images?: FileData[] | FilePondFile[];
}

/**
 * Add image upload using FilePond plugin.
 * Can upload a simple image, multiple images or both
 * @displayName ImagesUpload
 */
@Component({
  components: {
    FileUpload,
  },
})
export default class ImagesUpload extends Vue {
  /**
   * Hide upload simple image
   * @type {Boolean}
   * @default false
   */
  @Prop(Boolean) readonly hidePreview!: boolean;

  /**
   * Hide upload multiple images
   * @type {Boolean}
   * @default false
   */
  @Prop(Boolean) readonly hideImages!: boolean;

  /**
   * Hide Labels
   * @type {Boolean}
   * @default false
   */
  @Prop(Boolean) readonly hideLabel!: boolean;

  /**
   * Simple image label, final label is used with i18n (`this.$t()`)
   * @type {String}
   * @default "preview.image"
   */
  @Prop({ type: String, default: "preview.image" })
  readonly previewLabel!: string;

  /**
   * Property name for simple image
   * @type {String}
   * @default "preview_image"
   */
  @Prop({ type: String, default: "preview_image" })
  readonly previewValue!: string;

  /**
   * Multiple images label, final label is used with i18n (`this.$t()`)
   * @type {String}
   * @default "gallery.images"
   */
  @Prop({ type: String, default: "gallery.images" })
  readonly imagesLabel!: string;

  /**
   * Property name for multiple images
   * @type {String}
   * @default "images"
   */
  @Prop({ type: String, default: "images" })
  readonly imagesValue!: string;

  /**
   * Property to define cols length
   * @type {String|Number}
   * @default "12"
   */
  @Prop({ type: [String, Number, Boolean], default: "12" })
  readonly cols!: string | number | boolean;

  /**
   * Property to define lg cols length
   * @type {String|Number}
   * @default "6"
   */
  @Prop({ type: [String, Number, Boolean], default: "6" })
  readonly lg!: string | number | boolean;

  /**
   * Element object for v-model value
   * @model
   * @type {ElementItemWithImages}
   * @default {}
   */
  @VModel({ type: Object, default: () => ({}) }) model!: ElementItemWithImages;

  @Ref("pondPreviewImage") readonly pondPreview!: FileUpload;
  @Ref("pondImages") readonly pondImages!: FileUpload;

  /**
   * Open system dialog to select files
   * @param {boolean} bImages If true, allow multiple images. Default false
   */
  browse(bImages = false) {
    if (bImages) {
      this.pondImages.browse();
    } else {
      this.pondPreview.browse();
    }
  }

  /**
   * Remove uploaded image
   * @param {boolean} bImages If true, remove from multiples images instance
   * @param {string|number} sIndex If bImages is true, specify file index to be removed
   */
  remove(bImages = false, sIndex?: string | number) {
    if (bImages) {
      this.pondImages.remove(sIndex);
    } else {
      this.pondPreview.remove();
    }
  }

  onUpdateModel(sKey: string, sValue: any) {
    this.$emit(`update:${kebabCase(sKey)}`, sValue);

    if (this.model instanceof Model) {
      this.model.set(sKey, sValue);
    } else {
      this.$set(this.model, sKey, sValue);
    }
  }

  get previewModel() {
    return get(this.model, this.previewValue);
  }

  get imagesModel() {
    const arImages: FileData[] = get(this.model, this.imagesValue);
    return filter(arImages, (file) => !!file.path);
  }
}
</script>
