<template>
  <ValidationProvider
    ref="fieldCurrency"
    :name="$t(label)"
    :rules="required ? 'required' : ''"
    :vid="label"
    slim
  >
    <template #default="{ errors, valid }">
      <v-select
        v-model="iCurrencyId"
        :chips="multiple"
        :disabled="disabled"
        :error-messages="required ? errors : undefined"
        :multiple="multiple"
        :small-chips="multiple"
        :success="required && valid"
        hide-details="auto"
        v-bind="obData"
        @change="onChange"
      >
        <template #item="{ item }">
          <v-list-item
            v-if="multiple"
            ripple
            @click="onToggleItem(item.id)"
            @mousedown.prevent
          >
            <v-list-item-action>
              <v-icon
                :color="
                  arSelectedIdList.includes(item.id) ? 'primary darken-4' : ''
                "
              >
                {{
                  arSelectedIdList.includes(item.id)
                    ? "mdi-checkbox-marked-outline"
                    : "mdi-checkbox-blank-outline"
                }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <currency-preview v-if="item.id" :item="item" :size="24" />
              <v-list-item-subtitle v-else v-text="item.name" />
            </v-list-item-content>
          </v-list-item>
          <template v-else>
            <currency-preview v-if="item.id" :item="item" :size="24" />
            <v-list v-else color="transparent">
              <v-list-item>
                <v-list-item-subtitle v-text="item.name" />
              </v-list-item>
            </v-list>
          </template>
        </template>

        <template v-if="multiple" #prepend-item>
          <v-list-item ripple @click="onToggle" @mousedown.prevent>
            <v-list-item-action>
              <v-icon
                :color="
                  isSomeSelected || isAllSelected > 0 ? 'primary darken-4' : ''
                "
              >
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="$t('select.all')" />
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-select>
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import { Currency, CurrencyData } from "@planetadeleste/vue-mc-shopaholic";
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import CurrencyPreview from "@/modules/currencies/components/CurrencyPreview.vue";
import { AppModule } from "@/store/app";
import { CompanySettingsData } from "@planetadeleste/vue-mc-gw";
import useInvoice from "@/composables/invoice";
import {
  castArray,
  chain,
  defaults,
  filter,
  find,
  get,
  isArrayLike,
  isEmpty,
  map,
} from "lodash";
import { ConfigModule } from "@/store/config";

@Component({
  components: { CurrencyPreview },
})
export default class CurrencySelect extends Vue {
  @VModel({ type: [Number, Array] }) iCurrencyId!: number | number[];
  @Prop({ type: String, default: "currency" }) readonly label!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly clearable!: boolean;
  @Prop(Boolean) readonly autoassign!: boolean;
  @Prop(Boolean) readonly multiple!: boolean;
  @Prop(Boolean) readonly onlyUsed!: boolean;
  @Prop(Boolean) readonly companyOnly!: boolean;
  @Prop(Boolean) readonly emptyOption!: boolean;
  @Prop(Boolean) readonly hideLabel!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop({ type: String, default: "all" }) readonly emptyLabel!: string;
  @Prop(Array) readonly ids!: number[];

  arCurrencyIdList: number[] = [];

  get obData() {
    return defaults(this.$attrs, {
      label: this.hideLabel ? undefined : this.$t(this.label),
      items: this.items,
      menuProps: { offsetY: true },
      hideDetails: this.sHideDetails,
      itemValue: "id",
      itemText: "name",
      outlined: true,
      dense: true,
      clearable: this.clearable,
    });
  }

  get items(): Partial<CurrencyData>[] {
    const arModels =
      this.obCollection.getModelList() as Partial<CurrencyData>[];
    let arIdList: number[] = [];

    if (this.emptyOption) {
      arModels.push({
        id: 0,
        name: this.$t(this.emptyLabel) as string,
      });
    }

    if (this.ids && this.ids.length) {
      arIdList = this.arCurrencyIdList.length
        ? filter(this.ids, (id: number) => this.arCurrencyIdList.includes(id))
        : [...this.ids];
    } else if (this.arCurrencyIdList) {
      arIdList = [...this.arCurrencyIdList];
    }

    if (this.companyOnly && this.companyCurrencyIdList.length) {
      arIdList = arIdList.length
        ? arIdList.filter((id) => this.companyCurrencyIdList.includes(id))
        : this.companyCurrencyIdList;
    }

    return arIdList && arIdList.length
      ? filter(arModels, (obCurrency) => {
          return this.emptyOption
            ? !obCurrency.id || arIdList.includes(obCurrency.id)
            : obCurrency.id
            ? arIdList.includes(obCurrency.id)
            : false;
        })
      : arModels;
  }

  get obCollection() {
    return AppModule.currencies;
  }

  get company() {
    return AppModule.company;
  }

  get companyCurrencyIdList() {
    return ConfigModule.companyCurrencyIdList;
  }

  get settings(): CompanySettingsData {
    return this.company.get("settings", {});
  }

  get iDefaultCurrencyId(): number | null {
    return get(this.settings, "currency_id", null);
  }

  get defaultCurrency() {
    return this.iDefaultCurrencyId
      ? find(this.items, { id: this.iDefaultCurrencyId })
      : undefined;
  }

  get loading() {
    return this.obCollection.loading;
  }

  get sHideDetails() {
    return get(this.$attrs, "hideDetails", "auto");
  }

  get arSelectedIdList() {
    return this.multiple ? castArray(this.iCurrencyId) : [];
  }

  get isAllSelected(): boolean {
    return this.multiple && this.arSelectedIdList.length === this.items.length;
  }

  get isSomeSelected(): boolean {
    return (
      this.multiple && this.arSelectedIdList.length > 0 && !this.isAllSelected
    );
  }

  get icon() {
    if (this.isAllSelected) return "mdi-close-box";
    if (this.isSomeSelected) return "mdi-minus-box";
    return "mdi-checkbox-blank-outline";
  }

  @Watch("items", { deep: true, immediate: true })
  onChangeItems() {
    this.onAutoAssign();
  }

  async mounted() {
    if (this.onlyUsed) {
      this.arCurrencyIdList = await useInvoice().listByType<number[]>(
        "currency_id"
      );
    }

    this.$nextTick(() => {
      this.onAutoAssign();
    });
  }

  onAutoAssign() {
    if (!this.autoassign || !this.items.length) {
      return;
    }

    let iCurrencyId = this.iCurrencyId;

    if (!iCurrencyId || !find(this.items, { id: iCurrencyId })) {
      let obCurrency =
        this.defaultCurrency ??
        chain(this.items)
          .filter((obCurrency) => !obCurrency.external_id && !!obCurrency.id)
          .first()
          .value();

      if (!obCurrency && this.items.length === 1) {
        obCurrency = this.items[0];
      }

      if (obCurrency && obCurrency.id) {
        iCurrencyId = obCurrency.id;
      }
    }

    if (iCurrencyId) {
      this.onChange(iCurrencyId);
    }
  }

  onToggle() {
    this.$nextTick(() => {
      if (this.isAllSelected) {
        this.onChange([]);
      } else {
        this.onChange(map(this.items, "id") as number[]);
      }
    });
  }

  onToggleItem(iValue: number) {
    if (!this.multiple) {
      this.onChange(iValue);
      return;
    }

    const arSelectedItems = castArray(this.iCurrencyId);

    if (!arSelectedItems.includes(iValue)) {
      arSelectedItems.push(iValue);
    } else {
      const iSelectedIndex = arSelectedItems.indexOf(iValue);
      if (iSelectedIndex !== -1) {
        arSelectedItems.splice(iSelectedIndex, 1);
      }
    }

    this.onChange(arSelectedItems);
  }

  onChange(iValue: number | number[]) {
    if (!iValue || (isArrayLike(iValue) && isEmpty(iValue))) {
      this.$emit("input", this.multiple ? [] : 0);
      this.$emit("change", null);

      return;
    }

    if (this.multiple) {
      iValue = castArray(iValue) as number[];

      this.$emit(
        "change",
        this.obCollection.filter((obModel: Currency) =>
          // @ts-ignore
          iValue.includes(obModel.id)
        )
      );
    } else {
      const obCurrency = this.obCollection.find({ id: iValue });
      this.$emit("change", obCurrency);
    }

    this.$emit("input", iValue);
  }

  getFlag(sCode: string) {
    return require(`@icon/flags/${sCode.toLowerCase()}.svg`);
  }
}
</script>
