import { company } from "@/router/permissions";
import { create } from "@/router/utils";
import { find, findIndex } from "lodash";

const obRoute = create(
  "contracts",
  null,
  "index",
  {
    sortOrder: 999,
    iconProps: { icon: "invoice-alt" },
  },
  company
);

if (obRoute.children?.length) {
  const obUpdateRoute = find(obRoute.children, { name: "update" });
  const obCreateRoute = find(obRoute.children, { name: "create" });
  const iUpdateIndex = findIndex(obRoute.children, { name: "update" });
  const iCreateIndex = findIndex(obRoute.children, { name: "create" });

  if (obUpdateRoute && obCreateRoute) {
    obUpdateRoute.page = "form";
    obCreateRoute.page = "form";

    obRoute.children.splice(iUpdateIndex, 1, obUpdateRoute);
    obRoute.children.splice(iCreateIndex, 1, obCreateRoute);
  }
}
export default [obRoute];
