import { Vue, Component, Watch } from "vue-property-decorator";
import { FlashModule } from "@/store/flash";
import { TYPE } from "vue-toastification";

@Component
export default class FlashMixin extends Vue {
  @Watch("flashDisplay")
  onFlashDisplayChanged(val: boolean): void {
    if (val) {
      this.$toast(this.flashMessage, {
        type: this.flashVariant,
        onClose: () => {
          this.flashClose();
        },
      });
    }
  }

  get flashDisplay(): boolean {
    return FlashModule.flashed;
  }

  get flashVariant(): TYPE {
    return FlashModule.variant;
  }

  get flashMessage(): string {
    return FlashModule.flashMessage;
  }

  flashClose(): void {
    FlashModule.closeFlash();
  }
}
