import { Component, Mixins, Vue } from "vue-property-decorator";
import GlobalMixin from "@/mixins/GlobalMixin";
import { AuthModule } from "@/store/auth";
import {
  Auth as AuthModel,
  Profile,
  ResponseLoginRegisterData,
  UserRegisterOptions,
} from "@planetadeleste/vue-mc-shopaholic";
import { LayoutModule } from "@/store/layout";
import VueI18n from "vue-i18n";
import { Result } from "vue-mc";
import { buildAbilityFor } from "@/services/ability";
import { FlashModule } from "@/store/flash";

@Component
export default class AuthMixin extends Mixins(GlobalMixin) {
  user!: Profile;

  created(): void {
    this.user = AuthModule.user;
  }

  /**
   * Enter to the platform by user login auth
   * @param login
   * @param password
   */
  async logIn(
    login: string,
    password: string
  ): Promise<ResponseLoginRegisterData | boolean> {
    // Reset auth store vars
    AuthModule.logout();

    this.user = new Profile();
    const response: Result<ResponseLoginRegisterData> = await this.user
      .login(login.toLowerCase(), password)
      .then((res) => res.getData());

    if (!response || !response.status) {
      let sError: string | null | VueI18n.TranslateResult = this.$_.get(
        response,
        "message"
      );

      if (!sError || sError === "invalid_credentials") {
        sError = await this.$t("invalid_credentials");
      }

      this.flashError(sError);
      return false;
    }

    return this.auth(response.data);
  }

  async register(
    params: UserRegisterOptions
  ): Promise<false | ResponseLoginRegisterData> {
    this.user = new Profile();
    const response = await this.user.register(params);

    if (!response || this.$_.has(response, "error")) {
      let msg = "Ha habido un problema al registrar tu cuenta";
      msg = this.$_.get(response, "error", msg) as string;
      this.flashError(msg);
      return false;
    }

    const obData = response.getData();

    if (!obData.status) {
      if (obData.message) {
        this.flashError(obData.message);
      }

      return false;
    }

    return await this.auth(obData.data);
  }

  /**
   * Exit from platform and clear user login data
   */
  async logOut(redirect = true): Promise<void> {
    await this.user.silenty().logout();
    AuthModule.logout();
    await LayoutModule.setLayout("init");

    this.clearLogin(redirect);
  }

  /**
   * Clear user login data
   */
  clearLogin(redirect = true): void {
    if (redirect && this.$route.name !== "home") {
      this.$router.push({ name: "home" });
    }
  }

  /**
   * Collect login data and set app token
   * @author Alvaro Canepa <bfpdevel@gmail.com>
   * @param {ResponseLoginRegisterData} data
   * @return {Promise<ResponseLoginRegisterData>}
   * @memberof Auth
   */
  async auth(
    data: ResponseLoginRegisterData
  ): Promise<ResponseLoginRegisterData> {
    if (data.token && data.expires_in && this.user) {
      localStorage.setItem("access_token", data.token);
      localStorage.setItem("refresh_token", data.token);

      const tokensExpiry = this.$dayjs()
        .add(data.expires_in, "s")
        .toISOString();
      localStorage.setItem("expires_in", tokensExpiry);

      if (data.user) {
        this.user.set(data.user);
        const sUser = JSON.stringify(data.user);
        localStorage.setItem("user", sUser);
        Vue.prototype.$user.set(this.user);

        AuthModule.loginSuccess(this.user);

        // Update rules
        const ability = buildAbilityFor(this.user);
        this.$ability.update(ability.rules);
      }
    }

    return data;
  }

  initSession(): Promise<unknown> {
    return new Promise(() => {
      if (AuthModule.isLogged) {
        const tokenExpiryDate = localStorage.getItem("expires_in");
        if (!tokenExpiryDate) {
          return this.logOut();
        }

        const tenMinutesBeforeExpiry = this.$dayjs(tokenExpiryDate).subtract(
          10,
          "m"
        );
        if (this.$dayjs().isAfter(tenMinutesBeforeExpiry)) {
          return this.logOut();
        }

        this.$_.delay(
          this.refreshAccessToken,
          tenMinutesBeforeExpiry.diff(this.$dayjs())
        );

        if (!this.$route.name || this.$route.name == "account.login") {
          this.$router.push({ name: "home" });
        }
        LayoutModule.setLayout("main");

        // Display Welcome message
        if (FlashModule.displayWelcomeMessage) {
          FlashModule.hideWelcome();
          const msg = this.$t("welcome.message", {
            name: AuthModule.user.name,
          });
          this.flashSuccess(msg);
        }
      } else {
        LayoutModule.setLayout("login");
      }
    });
  }

  /**
   * Get new access token
   */
  async refreshAccessToken(): Promise<string | undefined> {
    const obAuthModel = new AuthModel();
    const response = await obAuthModel
      .refresh()
      .then((response) => response.getData());

    if (!response || !response.status) {
      this.logOut();
      return;
    }

    if (this.$_.has(response, "data.token")) {
      await this.auth(response.data);

      const tokenExpiryDate = localStorage.getItem("expires_in");
      if (!tokenExpiryDate) {
        return;
      }

      const tenMinutesBeforeExpiry = this.$dayjs(tokenExpiryDate).subtract(
        10,
        "m"
      );

      this.$_.delay(
        this.refreshAccessToken,
        tenMinutesBeforeExpiry.diff(this.$dayjs())
      );
    }

    return response.data.token;
  }

  async reloadUserAvatar(reloadData = false): Promise<void> {
    this.getUser();

    if (!this.user || !AuthModule.isLogged) {
      throw "No se ha iniciado sesión";
    }

    // @ts-ignore
    reloadData ? await this.user.reload() : await this.user.loadAvatar();
  }

  async authCheck(): Promise<boolean> {
    let bLogged = false;

    try {
      const obAuthModel = new AuthModel();
      const response = await obAuthModel
        .silenty()
        .check()
        .then((response) => response.getData());
      bLogged = this.$_.get(response, "status", false);
    } catch (error) {
      return bLogged;
    }

    return bLogged;
  }

  getUser(): void {
    this.user = AuthModule.user;
  }

  get userInitials(): string | null {
    this.getUser();
    return !this.user
      ? null
      : this.$_.chain(this.fullName)
          .trim()
          .words()
          .filter((word) => word.length > 0)
          .map((word) => word.substring(0, 1))
          .join("")
          .toUpper()
          .value();
  }

  get fullName(): string {
    return this.user ? `${this.user.name} ${this.user.last_name}` : "";
  }

  get userIsAdmin() {
    return AuthModule.isAdmin;
  }

  get userIsCompany() {
    return AuthModule.isCompany;
  }
}
