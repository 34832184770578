<template>
  <v-app class="pde-app">
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      disable-resize-watcher
      light
      persistent
    >
      <main-drawer />
      <template #append>
        <bottom-nav />
      </template>
    </v-navigation-drawer>

    <app-bar
      :drawer="drawer"
      :right-drawer="rightDrawer"
      @change:drawer="setDrawer"
      @change:right-drawer="setDrawer($event, true)"
    />

    <v-divider />

    <v-main>
      <v-slide-y-transition mode="out-in" name="fade">
        <router-view />
      </v-slide-y-transition>
    </v-main>

    <v-navigation-drawer
      v-model="rightDrawer"
      :right="right"
      app
      fixed
      temporary
    >
      <right-drawer />
    </v-navigation-drawer>

    <main-footer :fixed="fixed" :title="title" />
    <loading />
    <print-invoice />
    <!-- <version-check /> -->
  </v-app>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import AuthMixin from "@/mixins/AuthMixin";
// import VersionCheck from "@/components/common/version/index.vue";
import MainDrawer from "@/layout/components/main/drawer/Main.vue";
import RightDrawer from "@/layout/components/main/drawer/Right.vue";
import MainFooter from "@/layout/components/main/Footer.vue";
import AppBar from "@/layout/components/main/AppBar.vue";
import { usePusher } from "@/services/pusher";
import { Channel } from "pusher-js";
import { InvoiceSignedChannel } from "@/types/broadcast";
import { InvoiceModule } from "@/store/invoice";
import { EventBus } from "@/services/event-bus";
import { ConfigModule } from "@/store/config";
import { CompanySettingsData, Invoice } from "@planetadeleste/vue-mc-gw";
import { number } from "mathjs";
import Loading from "@/components/common/loading/index.vue";
import BottomNav from "@/layout/components/main/drawer/BottomNav.vue";
import { AppModule } from "@/store/app";
import PrintInvoice from "@/components/common/PrintInvoice.vue";
import FlashMixin from "@/mixins/FlashMixin";

@Component({
  name: "main-layout",
  components: {
    PrintInvoice,
    BottomNav,
    Loading,
    MainDrawer,
    RightDrawer,
    MainFooter,
    AppBar,
  },
})
export default class MainLayout extends Mixins(AuthMixin, FlashMixin) {
  drawer = true;
  fixed = false;
  miniVariant = false;
  right = true;
  rightDrawer = false;

  obPusher = usePusher();
  obInvoiceSignedChannel: Channel | null = null;
  obInvoiceUnsignedChannel: Channel | null = null;

  /**
   * @todo Show current company name
   */
  get title(): string {
    return "Gestion Web";
  }

  get drawerWidth(): number {
    return this.$vuetify.breakpoint.width > 1500 ? 700 : 500;
  }

  get company() {
    return AppModule.company;
  }

  get companyId() {
    return AppModule.companyId;
  }

  get companySettings(): CompanySettingsData | undefined {
    return AppModule.companySettings;
  }

  created() {
    this.obInvoiceSignedChannel = this.obPusher.channel(
      "invoice",
      "signed",
      async (obData: InvoiceSignedChannel) => {
        await this.onGetSignedInvoiceMessage(obData);
      }
    );

    this.obInvoiceUnsignedChannel = this.obPusher.channel(
      "invoice",
      "unsigned",
      (obData: InvoiceSignedChannel) => {
        this.onGetUnsignedInvoiceMessage(obData);
      }
    );

    EventBus.on("app.company.selected", this.onSwitchCompany);
  }

  async onGetSignedInvoiceMessage(obData: InvoiceSignedChannel) {
    if (obData.user_id !== this.user.id) {
      return;
    }

    // Display success message
    if (obData.message) {
      this.$toast.success(obData.message);
    }

    // Remove singing status
    InvoiceModule.removeSign(obData.invoice_id);

    // Load invoice
    const obInvoice = new Invoice({ id: obData.invoice_id });
    await obInvoice.fetch();
    const sType = number(obInvoice.get("invoice_type.code", 0));
    let sPath = "invoices";

    if (this.companySettings?.print_after_billing) {
      EventBus.emit("invoice.print", obInvoice.attributes);
    }

    // Check for receipt invoice type (e-Recibo)
    if (sType === 701) {
      sPath += ".receipt";
    }

    if (this.$route.name && this.$route.name === `${sPath}.update`) {
      await this.$router.push({ name: `${sPath}.emitted` });
    }
  }

  onGetUnsignedInvoiceMessage(obData: InvoiceSignedChannel) {
    if (obData.user_id !== this.user.id) {
      return;
    }

    if (obData.message) {
      this.$toast.error(obData.message);
    }

    InvoiceModule.removeSign(obData.invoice_id, true);
  }

  beforeDestroy() {
    if (this.obInvoiceSignedChannel) {
      this.obInvoiceSignedChannel.disconnect();
    }

    if (this.obInvoiceUnsignedChannel) {
      this.obInvoiceUnsignedChannel.disconnect();
    }

    EventBus.off("app.company.selected", this.onSwitchCompany);
  }

  async mounted() {
    await this.onPreloadCompany();
  }

  setDrawer(bValue: boolean, bRight = false) {
    if (bRight) {
      this.rightDrawer = bValue;
    } else {
      this.drawer = bValue;
    }
  }

  async onSwitchCompany() {
    if (this.$route.name !== "home") {
      await this.$router.push({ name: "home" });
    }

    location.reload();
  }

  async onPreloadCompany() {
    await ConfigModule.loadCompanyCurrencyRates(true);
    await ConfigModule.loadCompanyInvoiceTypes(true);
    await ConfigModule.loadCompanyCfeCodes(true);
    await ConfigModule.loadCompanyModules(true);
  }

  private onLogout() {
    this.logOut();
  }
}
</script>
