import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import {
  CategoryCollection,
  CurrencyCollection,
  GroupCollection,
} from "@planetadeleste/vue-mc-shopaholic";
import {
  BankCollection,
  Branch,
  Company,
  CompanyData,
  CompanyMovementTypeCollection,
  CompanySettingsData,
} from "@planetadeleste/vue-mc-gw";
import {
  defaults,
  find,
  get,
  has,
  isEmpty,
  isNil,
  isString,
  set,
  toString,
} from "lodash";
import { EventBus } from "@/services/event-bus";
import { eqJson } from "@/plugins/helpers";
import { cleanCache } from "@/plugins/axios";

type FilterParam = {
  model: string;
  value: Record<string, any>;
  key?: string;
};

@Module({ dynamic: true, store, name: "app", namespaced: true })
class App extends VuexModule {
  private _companyMovementTypeConfig: CompanyMovementTypeCollection =
    new CompanyMovementTypeCollection();

  get companyMovementTypeConfig() {
    return this._companyMovementTypeConfig;
  }

  private _categories: CategoryCollection = new CategoryCollection();

  get categories() {
    return this._categories;
  }

  private _groups: GroupCollection = new GroupCollection();

  get groups() {
    return this._groups;
  }

  private _company: Company = new Company();

  get company(): Company {
    return this._company;
  }

  private _branch: Branch = new Branch();

  get branch() {
    return this._branch;
  }

  private _filters: Record<string, Record<string, any>> = {};

  get filters() {
    return this._filters;
  }

  private _currencies: CurrencyCollection = new CurrencyCollection();

  get currencies() {
    return this._currencies;
  }

  private _banks: BankCollection = new BankCollection();

  get banks() {
    return this._banks;
  }

  get companyId(): number | undefined {
    return this._company?.id;
  }

  get companySettings(): CompanySettingsData | undefined {
    return this._company?.settings as CompanySettingsData | undefined;
  }

  get companyTaxTypeIdList(): number[] | undefined {
    return this.companySettings?.tax_types;
  }

  get roles(): string[] {
    return this._groups.map("code");
  }

  get defaultBranch() {
    if (!this.company || !this.company.branches) {
      return undefined;
    }

    if (this.company.branches.length === 1) {
      return this.branch;
    }

    const obBranchData = find(this.company.branches, { is_default: true });
    return obBranchData ? new Branch(obBranchData) : this.branch;
  }

  get defaultCurrency() {
    return this.currencies.find({ is_default: true });
  }

  @Mutation
  setCompany(obCompanyData?: CompanyData) {
    const obData =
      obCompanyData instanceof Company
        ? obCompanyData.attributes
        : obCompanyData;
    localStorage.removeItem("cid");
    this._company = new Company(defaults({}, obData));

    if (this._company.id) {
      const sCompanyID = toString(this._company.id);
      localStorage.setItem("cid", sCompanyID);
    }

    EventBus.emit("app.company.selected", this._company.id);
  }

  @Mutation
  setBranch(iBranchID?: number) {
    localStorage.removeItem("cbid");
    const arBranches = this._company.branches;
    if (isNil(arBranches) || isEmpty(arBranches)) return;

    const obBranchData = iBranchID
      ? find(arBranches, { id: iBranchID })
      : find(arBranches, { is_default: true });
    if (!obBranchData) return;

    this._branch = new Branch(obBranchData);
    localStorage.setItem("cbid", toString(this._branch.id));

    EventBus.emit("app.branch.selected", iBranchID);
  }

  @Action
  async loadCategories() {
    this._categories.clear();
    await this._categories.tree();
  }

  @Action
  async loadGroups() {
    await this._groups.fetch();
  }

  @Action
  async pickCompany(obCompany: CompanyData) {
    this.setCompany(obCompany);
    this.setBranch();
    cleanCache();
    await this.loadCompanyMovementTypeConfig();
  }

  @Action
  unsetCompany() {
    this.setCompany();
    this.setBranch();
  }

  @Action
  pickBranch(iBranchID?: number) {
    this.setBranch(iBranchID);
  }

  @Action
  async loadCurrencies() {
    this._currencies.clear();
    await this._currencies.filterBy({ active: 1 }).list();
  }

  @Action
  async loadCompanyMovementTypeConfig() {
    this._companyMovementTypeConfig.clear();
    this._companyMovementTypeConfig.page(1);
    await this._companyMovementTypeConfig.fetch();
  }

  @Mutation
  setFilter(obFilterData: FilterParam) {
    // Check for changes
    const obFilters = { ...this._filters };
    const obModelFilters = get(obFilters, obFilterData.model);

    if (eqJson(obModelFilters, obFilterData.value)) {
      return;
    }

    set(obFilters, obFilterData.model, obFilterData.value);
    this._filters = obFilters;

    EventBus.emit("filters.change", obFilterData.model);
  }

  @Action({ commit: "setFilter" })
  addFilter(obFilterData: FilterParam) {
    const sContainer = obFilterData.model;
    const sValue = obFilterData.value;
    const sKey = obFilterData.key;
    let obFilters = has(this._filters, sContainer)
      ? get(this._filters, sContainer, {})
      : {};

    if (sValue && isString(sKey)) {
      set(obFilters, sKey, sValue);
    } else {
      obFilters = sValue;
    }

    return {
      model: sContainer,
      value: obFilters,
    };
  }

  @Action
  async loadBanks() {
    this._banks.clear();
    await this._banks.filterBy({ active: 1 }).list();
  }
}

export const AppModule = getModule(App);
